<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <ModalLayout :name="name">
      <div class="modal-task">
        <div class="modal-task__title">Это верный ответ!</div>
        <div class="modal-task__cell">
          <span>Вам начислено</span><br />
          <strong class="coin"
            ><span>18</span>
            <img
              src="@/assets/img/pic2/coin.svg"
              width="15"
              height="15"
              alt=""
            />
            <span>{{ COINS }}</span></strong
          >
        </div>

        <div class="modal-task__body">
          <button
            @click="$modal.hide(name)"
            class="button button-default button_big modal-task__button"
          >
            ОК
          </button>
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import ModalLayout from "@/layout/ModalLayout.vue";

export default {
  components: {
    ModalLayout,
  },
  data() {
    return {
      name: "result",
    };
  },
  methods: {
    beforeOpen() {
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    handleClick() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-task__cell {
  margin-top: 20px;
}
</style>

<template>
  <div class="chart">
    <div class="chart-scale">
      <div class="chart-scale__inner">
        <div
          class="chart-scale__value"
          :style="{
            transform: `rotate(${calcDeg}deg)`,
          }"
        >
          <span :class="color"></span>
        </div>
      </div>
    </div>
    <div class="chart__inner">
      <div class="chart__desc">{{ desc }}</div>
      <!-- <div class="chart__title">{{ title }}</div> -->
      <div class="chart__value">{{ value | mathRound }}{{ unit }}</div>
      <!-- <div>{{ calcDeg }}</div> -->
      <div class="chart__link">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PanelDount",
  props: {
    desc: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    link: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "purple",
    },
  },
  computed: {
    calcDeg() {
      const offset = -180;
      const total = 180;
      const percent = this.total ? (this.value / this.total) * 100 : this.value;
      const deg = (total * percent) / 100;

      return offset + deg;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 156px;
  height: 156px;
  border: 5px solid #35978c;
  border-radius: 156px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  background-color: #030506;
  color: #fff;
  &__desc {
    font-size: 12px;
    min-height: 28px;
    text-align: center;
    color: #01c3c1;
    padding: 0 15px;
    display: flex;
    align-items: flex-end;
    // align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: 21px;
    min-height: 24px;
    color: #50ffeb;
  }
  &__value {
    font-size: 32px;
    min-height: 40px;
    color: #50ffeb;
  }
  &__link {
    button {
      @include reset-button;
      font-size: 12px;
      color: #d5fff5;
      position: relative;
      &::before {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #00dfdf;
        box-shadow: 0px 0px 5px #2dffff;
        content: "";
        margin-top: 1px;
        transition: 0.2s;
        pointer-events: none;
      }
      &:hover {
        &::before {
          opacity: 0;
        }
      }
      // text-decoration: underline;
      // &:hover {
      //   text-decoration: none;
      // }
    }
  }
  &-scale {
    position: absolute;
    width: 138px;
    height: 69px;
    overflow: hidden;
    top: 4px;
    left: 4px;
    pointer-events: none;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      content: "";
      border: 7px solid #3e525e;
      border-radius: 100%;
    }
    &::after {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(200% + 2px);
      content: "";
      border-radius: 100%;
      background-image: url(~@/assets/img/pic2/circle.svg);
      background-size: cover;
    }
    &__value {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: hidden;
      transform-origin: 50% 100%;
      > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200%;
        content: "";
        border: 7px solid #8f20ff;
        border-radius: 100%;
        &.purple {
          border-color: #8f20ff;
        }
        &.green {
          border-color: #0bff65;
        }
        &.pink {
          border-color: #f32bac;
        }
      }
    }
  }
}
</style>
